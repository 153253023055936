import { render, staticRenderFns } from "./transition-expand.vue?vue&type=template&id=b030556a&scoped=true&"
import script from "./transition-expand.vue?vue&type=script&lang=js&"
export * from "./transition-expand.vue?vue&type=script&lang=js&"
import style0 from "./transition-expand.vue?vue&type=style&index=0&id=b030556a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b030556a",
  null
  
)

export default component.exports